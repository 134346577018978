// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.actions {
  display: flex;
  gap: 10px;
}

@media (max-width: 768px) {
  .container {
    align-items: center;
  }

  .top-bar {
    flex-direction: column;
    align-items: center;
  }

  .actions {
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 15px;
  }

  .top-bar > * {
    margin-bottom: 15px;
    width: 100%;
  }

  .connect-button {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE;IACE,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;IACtB,WAAW;IACX,mBAAmB;IACnB,SAAS;EACX;;EAEA;IACE,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  padding: 20px;\n}\n\n.top-bar {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n  margin-bottom: 20px;\n}\n\n.actions {\n  display: flex;\n  gap: 10px;\n}\n\n@media (max-width: 768px) {\n  .container {\n    align-items: center;\n  }\n\n  .top-bar {\n    flex-direction: column;\n    align-items: center;\n  }\n\n  .actions {\n    flex-direction: column;\n    width: 100%;\n    align-items: center;\n    gap: 15px;\n  }\n\n  .top-bar > * {\n    margin-bottom: 15px;\n    width: 100%;\n  }\n\n  .connect-button {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
