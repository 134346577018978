// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connect__button {
  background-color: antiquewhite;
  color: black;
  width: 180px;
  padding: 10px 16px;
  border-radius: 4px;
  border: 1.5px solid gray;
  cursor: pointer;
  display: inline-block;
  transition: background-color 0.3s;
  margin: 5px;
}

.connect__button:hover {
  background-color: rgb(233, 192, 139);
}

@media (max-width: 768px) {
  .connect__button {
    width: 100%;
    font-size: 16px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/connect/ConnectButton.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,wBAAwB;EACxB,eAAe;EACf,qBAAqB;EACrB,iCAAiC;EACjC,WAAW;AACb;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE;IACE,WAAW;IACX,eAAe;EACjB;AACF","sourcesContent":[".connect__button {\n  background-color: antiquewhite;\n  color: black;\n  width: 180px;\n  padding: 10px 16px;\n  border-radius: 4px;\n  border: 1.5px solid gray;\n  cursor: pointer;\n  display: inline-block;\n  transition: background-color 0.3s;\n  margin: 5px;\n}\n\n.connect__button:hover {\n  background-color: rgb(233, 192, 139);\n}\n\n@media (max-width: 768px) {\n  .connect__button {\n    width: 100%;\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
