import { Employee } from '../interfaces/Employee';

export const validateForm = (
  newEmployee: Employee,
  employees: Employee[],
  setError: React.Dispatch<React.SetStateAction<string>>,
): boolean => {
  const { full_name, wallet_address, salary } = newEmployee;

  if (!full_name.trim()) {
    setError('Введите ФИО сотрудника');
    return false;
  }

  if (employees.some((employee) => employee.full_name === full_name)) {
    setError('Такой сотрудник уже работает');
    return false;
  }

  if (!wallet_address.trim()) {
    setError('Введите адрес кошелька сотрудника');
    return false;
  }

  if (!wallet_address.startsWith('0x') || wallet_address.length !== 42) {
    setError(
      'Адрес кошелька должен начинаться с "0x" и иметь длину 42 символа',
    );
    return false;
  }

  if (!salary.trim()) {
    setError('Введите оклад сотрудника');
    return false;
  }

  if (isNaN(Number(salary))) {
    setError('Оклад должен быть числом');
    return false;
  }

  setError('');
  return true;
};
