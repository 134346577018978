import { EmployeeTableProps } from '../../interfaces/props/EmployeeTableProps';
import './EmployeeTable.css';

const EmployeeTable: React.FC<EmployeeTableProps> = ({
  employees,
  searchQuery,
  searchWalletQuery,
  onEdit,
}) => {
  const filteredEmployees = employees.filter(
    (employee) =>
      employee.full_name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      employee.wallet_address
        .toLowerCase()
        .includes(searchWalletQuery.toLowerCase()),
  );

  return (
    <table className="employee-table">
      <thead className="employee-table__header">
        <tr>
          <th className="employee-table__header-cell">Сотрудник</th>
          <th className="employee-table__header-cell">Кошелек</th>
          <th className="employee-table__header-cell">Оклад</th>
          <th className="employee-table__header-cell">Премия</th>
          <th className="employee-table__header-cell">Штрафы</th>
          <th className="employee-table__header-cell">Выплата</th>
          <th className="employee-table__header-cell">Действия</th>
        </tr>
      </thead>
      <tbody>
        {filteredEmployees.map((employee, index) => (
          <tr key={index} className="employee-table__row">
            <td className="employee-table__cell">
              <span className="employee-table__cell--label">Сотрудник:</span>
              {employee.full_name}
            </td>
            <td className="employee-table__cell employee-table__cell--wallet">
              <span className="employee-table__cell--label">Кошелек:</span>
              {employee.wallet_address}
            </td>
            <td className="employee-table__cell">
              <span className="employee-table__cell--label">Оклад:</span>
              {parseInt(employee.salary)}
            </td>
            <td className="employee-table__cell">
              <span className="employee-table__cell--label">Премия:</span>
              {parseInt(employee.bonus)}
            </td>
            <td className="employee-table__cell">
              <span className="employee-table__cell--label">Штрафы:</span>
              {parseInt(employee.penalty)}
            </td>
            <td className="employee-table__cell">
              <span className="employee-table__cell--label">Выплата:</span>
              {parseInt(employee.salary) +
                parseInt(employee.bonus) -
                parseInt(employee.penalty)}
            </td>
            <td className="employee-table__cell">
              <button
                className="employee-table__button"
                onClick={() => onEdit(employee.wallet_address)}
              >
                Изменить
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default EmployeeTable;
