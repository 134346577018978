// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search {
  display: inline-block;
}

.search__input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 300px;
  max-width: 100%;
  margin-left: 10px;
}

@media (max-width: 1124px) {
  .search__input {
    margin: 5px;
  }
}

@media (max-width: 768px) {
  .search {
    display: flex;
    flex-direction: column;
  }

  .search__input {
    width: 94%;
    margin-left: 0;
    margin-bottom: 10px;
  }

  .search__input:last-child {
    margin-bottom: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/search/SearchComponent.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,UAAU;IACV,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".search {\n  display: inline-block;\n}\n\n.search__input {\n  padding: 8px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  font-size: 16px;\n  width: 300px;\n  max-width: 100%;\n  margin-left: 10px;\n}\n\n@media (max-width: 1124px) {\n  .search__input {\n    margin: 5px;\n  }\n}\n\n@media (max-width: 768px) {\n  .search {\n    display: flex;\n    flex-direction: column;\n  }\n\n  .search__input {\n    width: 94%;\n    margin-left: 0;\n    margin-bottom: 10px;\n  }\n\n  .search__input:last-child {\n    margin-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
