import axios from 'axios';
import { parseUnits } from 'ethers';
import { getContract } from '../utils/getContract';

export const handleUpdateBonus = async (
  walletAddress: string,
  editBonus: string,
  onClose: () => void,
  setLoading: (loading: boolean) => void,
) => {
  setLoading(true);

  try {
    const contract = await getContract();
    const employeeNumber = await contract.checkEmployeeNumber(walletAddress);
    console.log(employeeNumber);

    const tx = await contract.setEmployeeBonus(
      employeeNumber,
      parseUnits(editBonus.toString(), 18),
      { gasLimit: 2000000 },
    );

    const receipt = await tx.wait();

    if (receipt?.status !== 1) {
      console.error('Ошибка при выполнении транзакции на смарт-контракте');
      alert('Ошибка при выполнении транзакции на смарт-контракте');
      return;
    }

    await axios.put(`${process.env.REACT_APP_API_URL}/employees/bonus`, {
      wallet_address: walletAddress,
      bonus: editBonus,
    });

    onClose();
    window.location.reload();
  } catch (error: any) {
    if (error.response) {
      console.error(
        'Error making request to the database:',
        error.response.data,
      );
      alert('Ошибка при работе с базой данных');
    } else {
      console.error('Failed to update bonus for employee:', error);
      alert('Ограничения на смарт-контракте');
    }
  } finally {
    setLoading(false);
  }
};
